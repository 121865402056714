<template></template>

<script>
export default {
  async created() {
    this.$store.dispatch("session/logout").then(() => {
      this.$router.push("/login");
    });
  },
};
</script>

<style></style>
